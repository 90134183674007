// body {
//   padding-top: $pt-navbar-height;
//   min-height: calc(100vh - $pt-navbar-height);
//   background-color: $light-gray4;
// }

// .root {
//   min-height: calc(100vh - $pt-navbar-height);
//   max-height: calc(100vh - $pt-navbar-height);
//   min-width: 100vw;
//   padding: 0;
//   margin: 0;
//   display: flex;
//   background-color: $light-gray4;
// }

.mr-auto {
  margin-right: auto;
}
.flex-row {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
}
.flex-grow {
  flex-grow: 1;
}
.flex-grow2 {
  flex-grow: 2;
}
.flex-grow3 {
  flex-grow: 3;
}
.container {
  padding: 16px;
  overflow-y: auto;
}

// button {
//   a {
//     color: unset;
//   }
// }
