.page-container {
  display: grid;
  width: 100%;
  min-height: 100%;
  grid-template-rows: 80px calc(
      100vh - 80px - var(--mantine-header-height, 0px) -
        var(--mantine-footer-height, 0px) - 32px
    ); // TODO
  grid-template-columns: 9fr 360px;
  column-gap: 16px;
  grid-template-areas:
    'header details'
    'content details';
}

.header {
  grid-area: header;
  margin: 8px;
}

.toolbar {
  grid-area: toolbar;
  background: white;
  height: 30px;
}

.content {
  grid-area: content;
  display: flex;
  flex-direction: column;
}

.details {
  grid-area: details;
}

.add-new-btn {
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 8px;
}

.add-transaction-form {
  display: grid;
  grid-template-rows: 1fr min-content;
  grid-template-columns: 200px minmax(160px, 1fr) minmax(160px, 1fr) 3fr 240px;
  column-gap: 8px;
  row-gap: 8px;
  grid-template-areas: 'date payee category description amount' '. . . actions actions';

  .action-container {
    grid-area: actions;
    margin-left: auto;

    button {
      margin-left: 16px;
    }
  }
}
